<template>
  <SignInLayout :subTitle="this.$t('login.subtitle', { brandName })" :title="this.$t('login.title')">
    <v-container class="start_content">
      <form novalidate v-on:submit.prevent="submit">
        <v-row class="default-row-height">
          <v-col cols="12" sm="12">
            <v-tabs background-color="transparent" class="methods" grow>
              <v-tab class="methods__tab" @click="changeTab('email')">Email</v-tab>
              <v-tab class="methods__tab" @click="changeTab('phone')">Phone</v-tab>
            </v-tabs>
          </v-col>
          <v-col v-if="method === 'email'" cols="12" sm="12">
            <BaseInput
              v-model="email"
              :disabled="!!phoneNumber.length"
              :errors="emailErrors"
              :placeholder="$t('general.inputs.email')"
              fieldName="email"
              @blur="$v.email.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col v-if="method === 'phone'" cols="12" sm="12">
            <PhoneInput
              v-model="phoneNumber"
              :disabled="!!email.length"
              :errors="phoneNumberErrors"
              placeholder="Phone Number"
              @blur="$v.phoneNumber.$touch()"
              @change="onPhoneNumberChange"
            />
          </v-col>
          <v-col class="" cols="12" sm="12">
            <BaseInput
              v-model="firstName"
              :errors="firstNameErrors"
              :placeholder="$t('general.inputs.firstName')"
              fieldName="firstName"
              @blur="$v.firstName.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col class="" cols="12" sm="12">
            <BaseInput
              v-model="lastName"
              :errors="lastNameErrors"
              :placeholder="$t('general.inputs.lastName')"
              fieldName="lastName"
              @blur="$v.lastName.$touch()"
              @change="onFieldChange"
            />
          </v-col>

          <v-col cols="12" sm="12">
            <DatePicker
              :dense="true"
              :errors="birthDateErrors"
              :height="47"
              :maxDate="new Date().toISOString().substr(0, 10)"
              :placeholder="$t('general.inputs.dateOfBirth')"
              :value="birthDate"
              @blur="$v.birthDate.$touch()"
              @setDate="onBirthDateChange"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <BaseInput
              v-model="code"
              :errors="codeErrors"
              fieldName="code"
              maskPattern="XXXX-XXXX"
              placeholder="Activation code"
              @blur="$v.code.$touch()"
              @change="onFieldChange"
            />
          </v-col>
          <v-col cols="12" sm="12">
            <PrimaryButton :text="$t('login.button')" size="x-large" type="submit" />
          </v-col>
        </v-row>
      </form>
      <p class="invite-pin-activator" @click="invitePinDialog = true">{{ $t("login.invite.requestAccessCode") }}</p>
    </v-container>
    <Snackbar v-model="snackbar" :text="error" :timeout="4000" type="error" @toggleSnackbar="toggleSnackbar" />
    <ActivationRequest
      :dialog="invitePinDialog"
      @close="invitePinDialog = false"
      @openActivationCodeDialog="openActivationCodeDialog"
    />
    <ActivationCode :dialog="accessCodeDialog" @close="closeActivationCodeDialog" />
  </SignInLayout>
</template>
<script>
import { mapActions, mapState } from "pinia";
import { mask } from "vue-the-mask";
import { email, required } from "vuelidate/lib/validators";

import { InvitesApi } from "@/api/invites";
import DatePicker from "@/components/shared/DatePicker";
import PhoneInput from "@/components/shared/PhoneInput";
import Snackbar from "@/components/shared/Snackbar";
import SignInLayout from "@/components/SignInLayout";
import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useInvitesStore } from "@/pinia-store/invites";
import { calcAge } from "@/utils/conversion";
import { isValidEmail, isValidPhoneNumber } from "@/utils/validations";
import ActivationCode from "@/views/Landing/ActivationCode";
import ActivationRequest from "@/views/Landing/ActivationRequest";

const codeLength = (value) => {
  return value.length === 9;
};
export default {
  name: "StartPage",
  components: {
    PhoneInput,
    DatePicker,
    BaseInput,
    SignInLayout,
    PrimaryButton,
    Snackbar,
    ActivationRequest,
    ActivationCode,
  },
  validations() {
    const validations = {
      email: {},
      phoneNumber: {},
      birthDate: { required },
      firstName: { required },
      lastName: { required },
      code: { required, codeLength },
    };
    if (!isValidEmail(this.email)) validations.phoneNumber = { required };
    if (!isValidPhoneNumber(this.cleanPhone)) validations.email = { required, email };
    return validations;
  },
  directives: {
    mask,
  },
  data() {
    return {
      brandName: process.env.VUE_APP_BRAND_NAME,
      method: "email",
      firstName: "",
      email: "",
      phoneNumber: "",
      lastName: "",
      birthDate: null,
      code: "",
      showArrowUp: false,
      snackbar: false,
      error: "",
      invitePinDialog: false,
      accessCodeDialog: false,
    };
  },
  computed: {
    ...mapState(useInvitesStore, ["invitePin"]),
    phoneNumberErrors() {
      const errors = [];

      if (!this.$v.phoneNumber.$dirty || isValidEmail(this.email)) return errors;
      if (!isValidPhoneNumber(this.cleanPhone))
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.phone") }));
      return errors;
    },
    cleanPhone() {
      if (!this.phoneNumber) return "";
      return this.phoneNumber.trim().replace("(", "").replace(")", "").replaceAll(" ", "").replaceAll("-", "");
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty || isValidPhoneNumber(this.cleanPhone)) return errors;
      !this.$v.email.email && errors.push(this.$t("validation.email"));
      !this.$v.email.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.email") }));
      return errors;
    },
    maskPattern(value) {
      return value;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.firstName.$dirty) return errors;
      !this.$v.firstName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.firstName") }));

      return errors;
    },
    birthDateErrors() {
      const errors = [];
      if (!this.$v.birthDate.$dirty) return errors;
      !this.$v.birthDate.required && errors.push(this.$t("validation.required", { field: "Date of birth" }));
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.lastName.$dirty) return errors;
      !this.$v.lastName.required &&
        errors.push(this.$t("validation.required", { field: this.$t("general.inputs.lastName") }));
      return errors;
    },
    codeErrors() {
      const errors = [];
      if (!this.$v.code.$dirty) return errors;
      !this.$v.code.required && errors.push(this.$t("validation.required", { field: "Activation Code" }));
      !this.$v.code.codeLength &&
        errors.push(this.$t("validation.required", { field: "Activation Code must have 8 letters" }));
      return errors;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["setInvitePin", "setActivationCode"]),
    toggleSnackbar() {
      this.snackbar = !this.snackbar;
    },
    onPhoneNumberChange(value) {
      this.phoneNumber = value;
    },

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      try {
        const data = await InvitesApi.login({
          phoneNumber: this.phoneNumber,
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          birthDate: this.birthDate.toDateString(),
          code: this.code.split("-").join(""),
        });
        this.loading = false;
        if (data.id) {
          await this.$router.push({ path: `/auth/signup/${data.id}` });
        }
      } catch (err) {
        this.loading = false;
        this.snackbar = true;
        this.error = err?.response?.data?.message;
      }
    },
    onBirthDateChange(value) {
      if (calcAge(value) < 18) {
        snackBarEventBus.$emit(snackBarEventName, {
          message: "Registration is not allowed for age less than 18 years old",
          type: "info",
        });
        return;
      }
      this.birthDate = value;
    },
    changeTab(method) {
      if (method === "email") {
        this.phoneNumber = "";
        this.$v.phoneNumber.$reset();
      }
      if (method === "phone") {
        this.email = "";
        this.$v.email.$reset();
      }

      this.method = method;
    },
    onFieldChange(value, fieldName) {
      this[fieldName] = value;
    },

    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
    gotoLogin() {
      this.$router.push("/auth/signin");
    },
    openActivationCodeDialog() {
      this.invitePinDialog = false;
      this.accessCodeDialog = true;
    },
    closeActivationCodeDialog() {
      this.setInvitePin("");
      this.setActivationCode("");
      this.accessCodeDialog = false;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
.login_divider {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(51, 52, 62, 0.5);

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgba(51, 52, 62, 0.12);
    margin: auto;
  }

  &:before {
    margin-right: 15px;
  }

  &:after {
    margin-left: 15px;
  }
}

.start {
  &_content {
    text-align: center;
    width: 100%;
    margin: 10px auto;
    max-width: $mobile-width;

    .methods {
      width: 100%;
      margin-bottom: 6px;

      &__tab {
        text-transform: capitalize !important;
        font-size: 14px;
      }
    }
  }

  * {
    font-size: 18px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.invite-pin-activator {
  cursor: pointer;
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  margin-top: 20px;
}
</style>
